import React from 'react';
//import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import FyraSuiteImage from '../assets/fyra_suite.jpg';
import FyraSuiteAiImage from '../assets/fyra_suite_ai.png';
import EiapImage from '../assets/eiap.png';
import FyraApiImage from '../assets/data_platform.webp';
import theme from '../theme/theme';
import { useTranslation } from 'react-i18next';

const FyraSuiteSection: React.FC = () => {
  const { t } = useTranslation();
  const plans = [
    {
      name: t('fyraSuite.plans.lite.name'),
      price: t('fyraSuite.plans.lite.price'),
      features: [
        t('fyraSuite.plans.lite.feature1'),
        t('fyraSuite.plans.lite.feature2'),
        t('fyraSuite.plans.lite.feature3'),
      ],
    },
    {
      name: t('fyraSuite.plans.standard.name'),
      price: t('fyraSuite.plans.standard.price'),
      features: [
        t('fyraSuite.plans.standard.feature1'),
        t('fyraSuite.plans.standard.feature2'),
      ],
    },
    {
      name: t('fyraSuite.plans.advanced.name'),
      price: t('fyraSuite.plans.advanced.price'),
      features: [
        t('fyraSuite.plans.advanced.feature1'),
        t('fyraSuite.plans.advanced.feature2'),
        t('fyraSuite.plans.advanced.feature3'),
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography variant="h2">FYRA Suite</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Carousel>
              <CardMedia
                image={FyraSuiteImage}
                title="FyraSuite"
                style={{ aspectRatio: '8/5' }}
              />
              <CardMedia
                image={EiapImage}
                title="FyraSuite"
                style={{ aspectRatio: '8/5' }}
              />
              <CardMedia
                image={FyraSuiteAiImage}
                title="FyraSuite"
                style={{ aspectRatio: '8/5' }}
              />
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" gutterBottom>
              {t('fyraSuite.featuresTitle')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.integratedServiceTitle')}
            </Typography>
            <Typography paragraph>
              {t('fyraSuite.integratedServiceDesc1')}
            </Typography>
            <Typography paragraph>
              {t('fyraSuite.integratedServiceDesc2')}
            </Typography>
            <Typography paragraph>
              {t('fyraSuite.integratedServiceDesc3')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.noSetupTitle')}
            </Typography>
            <Typography paragraph>{t('fyraSuite.noSetupDesc1')}</Typography>
            <Typography paragraph>{t('fyraSuite.noSetupDesc2')}</Typography>
            <Typography paragraph>{t('fyraSuite.noSetupDesc3')}</Typography>
            <CardMedia
              image={FyraSuiteImage}
              title="Feature"
              style={{ aspectRatio: '8/5' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.portableTitle')}
            </Typography>
            <Typography paragraph>{t('fyraSuite.portableDesc1')}</Typography>
            <Typography paragraph>{t('fyraSuite.portableDesc2')}</Typography>
            <CardMedia
              image={FyraApiImage}
              title="Feature"
              style={{ aspectRatio: '7/5' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.ecosystemTitle')}
            </Typography>
            <Typography paragraph>{t('fyraSuite.ecosystemDesc1')}</Typography>
            <Typography paragraph>{t('fyraSuite.ecosystemDesc2')}</Typography>
            <Typography paragraph>{t('fyraSuite.ecosystemDesc3')}</Typography>
            <CardMedia
              image={EiapImage}
              title="Attachment"
              style={{ aspectRatio: '8/5' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.oRanTitle')}
            </Typography>
            <Typography paragraph>{t('fyraSuite.oRanDesc1')}</Typography>
            <Typography paragraph>{t('fyraSuite.oRanDesc2')}</Typography>
            <Typography paragraph>{t('fyraSuite.oRanDesc3')}</Typography>
            <CardMedia
              image={FyraSuiteAiImage}
              title="Days"
              style={{ aspectRatio: '16/9' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.plansTitle')}
            </Typography>
            <Grid container spacing={3}>
              {plans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h3">
                        {plan.name}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        {plan.price}
                      </Typography>
                      <List>
                        {plan.features.map((feature, idx) => (
                          <ListItem key={idx}>
                            <ListItemText primary={feature} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.lowPriceTitle')}
            </Typography>
            <Typography paragraph>{t('fyraSuite.lowPriceDesc1')}</Typography>
            <Typography paragraph>{t('fyraSuite.lowPriceDesc2')}</Typography>
            <Typography paragraph>{t('fyraSuite.lowPriceDesc3')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.farmerLowPriceTitle')}
            </Typography>
            <Typography paragraph>
              {t('fyraSuite.farmerLowPriceDesc1')}
            </Typography>
            <Typography paragraph>
              {t('fyraSuite.farmerLowPriceDesc2')}
            </Typography>
            <Typography paragraph>
              {t('fyraSuite.farmerLowPriceDesc3')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraSuite.useCasesTitle')}
            </Typography>
            <Typography paragraph>{t('fyraSuite.useCasesDesc')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      image={FyraSuiteImage}
                      title="FyraSuite"
                      style={{ aspectRatio: '8/5' }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        {t('fyraSuite.comingSoon')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default FyraSuiteSection;
