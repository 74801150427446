import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import FyraSuiteSection from '../components/FyraSuiteSection';
import Header from '../components/Header';
import Footer from '../components/Footer';

const FyraSuitePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth="md">
        <Box mt={8}>
          <Box id="fyrasuite">
            <FyraSuiteSection />
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default FyraSuitePage;
