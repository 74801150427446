import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';
import FyraSuiteImage from '../assets/fyra_suite.jpg';
import DataPlatformImage from '../assets/data_platform.webp';
import { GoldenRatioImage } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography
        variant="h3"
        align="center"
        sx={{ fontSize: { xs: '7vw', sm: 32 } }}
        gutterBottom
      >
        {t('nav.service')}
      </Typography>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec1.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec1.txt1')}</Typography>
          <Typography paragraph>{t('service.sec1.txt2')}</Typography>
          <Typography paragraph>
            <Link to="/fyrasuite">{t('service.sec1.txt3')}</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={FyraSuiteImage} alt="FYRA Suite" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, sm: 1, md: 0 } }}>
          <Paper elevation={0}>
            <GoldenRatioImage src={DataPlatformImage} alt="O-RAN Assistant" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: 0, sm: 0, md: 1 } }}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec2.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec2.txt1')}</Typography>
          <Typography paragraph>{t('service.sec2.txt2')}</Typography>
          <Typography paragraph>
            <Link to="/fyraapi">{t('service.sec2.txt3')}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
