import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import newsImage1 from '../assets/news_1.webp';
import newsImage2 from '../assets/news_2.webp';

const NewsSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={6}>
      <Typography variant="h3" align="center" gutterBottom>
        News
      </Typography>
      <Grid container spacing={4} mt={0} mb={8}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardActionArea
              component="a"
              href="https://prtimes.jp/main/html/rd/p/000000001.000151358.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CardMedia
                component="img"
                height="auto"
                image={newsImage1}
                alt="News Image"
              />
              <CardContent>
                <Typography variant="h6" component="div" align="left">
                  {t('news.linkText1')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardActionArea
              component="a"
              href="https://prtimes.jp/main/html/rd/p/000000002.000151358.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CardMedia
                component="img"
                height="auto"
                image={newsImage2}
                alt="News Image"
              />
              <CardContent>
                <Typography variant="h6" component="div" align="left">
                  {t('news.linkText2')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsSection;
