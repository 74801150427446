import React from 'react';
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactFormPage from './pages/ContactFormPage';
import ConfirmPage from './pages/ConfirmPage';
import FyraApiPage from './pages/FyraApiPage';
import FyraSuitePage from './pages/FyraSuitePage';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-form" element={<ContactFormPage />} />
        <Route path="/confirm" element={<ConfirmPage />} />
        <Route path="/fyraapi" element={<FyraApiPage />} />
        <Route path="/fyrasuite" element={<FyraSuitePage />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
