import React from 'react';
//import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import FyraDataPlatformImage from '../assets/data_platform.webp';
import FyraApiImage from '../assets/fyra_api_image.jpg';
import SampleAppImage from '../assets/sample_app.png';
import theme from '../theme/theme';
import { useTranslation } from 'react-i18next';

const FyraApiSection: React.FC = () => {
  const { t } = useTranslation();
  const plans = [
    {
      name: t('fyraApi.plans.lite.name'),
      price: t('fyraApi.plans.lite.price'),
      features: [
        t('fyraApi.plans.lite.feature1'),
        t('fyraApi.plans.lite.feature2'),
        t('fyraApi.plans.lite.feature3'),
        t('fyraApi.plans.lite.feature4'),
        t('fyraApi.plans.lite.feature5'),
      ],
    },
    {
      name: t('fyraApi.plans.standard.name'),
      price: t('fyraApi.plans.standard.price'),
      features: [
        t('fyraApi.plans.standard.feature1'),
        t('fyraApi.plans.standard.feature2'),
        t('fyraApi.plans.standard.feature3'),
        t('fyraApi.plans.standard.feature4'),
        t('fyraApi.plans.standard.feature5'),
      ],
    },
    {
      name: t('fyraApi.plans.advanced.name'),
      price: t('fyraApi.plans.advanced.price'),
      features: [
        t('fyraApi.plans.advanced.feature1'),
        t('fyraApi.plans.advanced.feature2'),
        t('fyraApi.plans.advanced.feature3'),
        t('fyraApi.plans.advanced.feature4'),
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography variant="h2">FYRA API</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Carousel>
              <CardMedia
                image={FyraDataPlatformImage}
                title="FyraApi"
                style={{ aspectRatio: '8/5' }}
              />
              <CardMedia
                image={FyraApiImage}
                title="FyraApi"
                style={{ aspectRatio: '8/5' }}
              />
              <CardMedia
                image={SampleAppImage}
                title="FyraApi"
                style={{ aspectRatio: '8/5' }}
              />
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" gutterBottom>
              {t('fyraApi.featuresTitle')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.integratedServiceTitle')}
            </Typography>
            <Typography paragraph>
              {t('fyraApi.integratedServiceDesc1')}
            </Typography>
            <Typography paragraph>
              {t('fyraApi.integratedServiceDesc2')}
            </Typography>
            <Typography paragraph>
              {t('fyraApi.integratedServiceDesc3')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.noSetupTitle')}
            </Typography>
            <Typography paragraph>{t('fyraApi.noSetupDesc1')}</Typography>
            <Typography paragraph>{t('fyraApi.noSetupDesc2')}</Typography>
            <Typography paragraph>{t('fyraApi.noSetupDesc3')}</Typography>
            <CardMedia
              image={FyraDataPlatformImage}
              title="Feature"
              style={{ aspectRatio: '7/5' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.portableTitle')}
            </Typography>
            <Typography paragraph>{t('fyraApi.portableDesc1')}</Typography>
            <Typography paragraph>{t('fyraApi.portableDesc2')}</Typography>
            <CardMedia
              image={FyraApiImage}
              title="Feature"
              style={{ aspectRatio: '4/3' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.lightnessTitle')}
            </Typography>
            <Typography paragraph>{t('fyraApi.lightnessDesc1')}</Typography>
            <Typography paragraph>{t('fyraApi.lightnessDesc2')}</Typography>
            <Typography paragraph>{t('fyraApi.lightnessDesc3')}</Typography>
            <CardMedia
              image={SampleAppImage}
              title="Attachment"
              style={{ aspectRatio: '8/5' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.dateSearchTitle')}
            </Typography>
            <Typography paragraph>{t('fyraApi.dateSearchDesc1')}</Typography>
            <Typography paragraph>{t('fyraApi.dateSearchDesc2')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.plansTitle')}
            </Typography>
            <Grid container spacing={3}>
              {plans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h3">
                        {plan.name}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        {plan.price}
                      </Typography>
                      <List>
                        {plan.features.map((feature, idx) => (
                          <ListItem key={idx}>
                            <ListItemText primary={feature} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.lowPriceTitle')}
            </Typography>
            <Typography paragraph>{t('fyraApi.lowPriceDesc1')}</Typography>
            <Typography paragraph>{t('fyraApi.lowPriceDesc2')}</Typography>
            <Typography paragraph>{t('fyraApi.lowPriceDesc3')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('fyraApi.useCasesTitle')}
            </Typography>
            <Typography paragraph>{t('fyraApi.useCasesDesc')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      image={FyraDataPlatformImage}
                      title="FyraApi"
                      style={{ aspectRatio: '8/5' }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        {t('fyraApi.comingSoon')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default FyraApiSection;
